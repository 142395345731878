<script setup lang="ts">
// PROPS
interface Props {
    page: number;
}
const props = defineProps<Props>();
const { page } = toRefs(props);

// EMIT
type Emit = {
    prevPage: [];
    nextPage: [];
};
defineEmits<Emit>();
</script>
<template>
    <div class="flex justify-end items-center w-full">
        <!-- Buttons -->
        <div class="inline-flex bg-white dark:text-white border border-stone-300 dark:border-stone-700 dark:bg-stone-800 rounded-lg">
            <button
                class="flex items-center justify-center px-5 h-10 text-sm font-medium rounded-s-lg border-0 border-e border-stone-300 dark:border-stone-700 hover:bg-stone-200 dark:hover:bg-stone-900 transition"
                @click="$emit('prevPage')"
            >
                Prev
            </button>
            <div class="flex items-center justify-center px-5 h-10 text-sm font-medium">
                {{ page }}
            </div>
            <button
                class="flex items-center justify-center px-5 h-10 text-sm font-medium rounded-e-lg border-0 border-s border-stone-300 dark:border-stone-700 hover:bg-stone-200 dark:hover:bg-stone-900 transition"
                @click="$emit('nextPage')"
            >
                Next
            </button>
        </div>
    </div>
</template>
